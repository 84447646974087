import '@CSS/index.scss';
import $ from 'jquery';
require('jquery-ui');
import React from 'react';
import { render } from 'react-dom';
import {rovingIndex} from 'roving-ux';

import Gallery from '@JS/index_components/Gallery';
import StartTrafficData from '@JS/DataCollection/trafficData';

var loadingGif="build/images/loading.png";

$(function(){
	StartTrafficData(5);
	GetLatestGallery();	
});

render(<img className='loadingGif' src={loadingGif} atl="Loading Gallery"></img>, document.getElementById('GalleryContent'));

function GetLatestGallery(){
	$.ajax({
		url:'/gallery/getList',
		method:'GET',
		success:function(data){
			if (data.length>0){
				render(Gallery(data), document.getElementById('GalleryContent'));
				rovingIndex({
					element: document.getElementById('GalleryContent'),
					target: '.galleryImageLink'
				});

			}else{
				$("#GalleryBodyContainerWrapper").html("");
				$("#GalleryBodyContainerWrapper").css("height","225px");
			}

		}
	});
}