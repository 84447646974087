import $ from 'jquery';

export default function StartTrafficData(timeOnSite=5){
	
	let url = document.referrer;
	if(url != null && url.length == 0){
		url = window.location.origin;
	}
	url = url.replace(/\/$/,"");
	let url_original=url;
	do{
		url_original = url
		url = url.replace(/((https)|(http)|(\:\/\/)|(www.))/,"");
	}while(url!=url_original);
	if(!url.includes("wetlandshoney")){
		$.ajax({
			url:'/traffic',
			method:'POST',
			data:{"previous_url":url},
			success:function(){
			}
		})
	}

	setInterval(function(){
		$.ajax({
			url:'/traffic',
			method:'POST',
			data:{timeOnSite:timeOnSite},
			success:function(){
			}
		})
	},timeOnSite * 1000);
}

