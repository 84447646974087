import React from 'react';

export default function Gallery(images=[]){
	let imageElements = [];
	images.forEach(function (image, index) {
		imageElements.push(
			<a className='galleryImageLink' href={"/uploads/" + image.filename + "." + image.extension} target="_blank" key={index}>
				<picture className="galleryImage">
					<source className="galleryImageWebp" srcSet={"/uploads/" + image.filename + ".webp"} type='image/webp'></source>
					<source className="galleryImageJpg" srcSet={"/uploads/" + image.filename + ".jpg"} type='image/jpeg'></source>
					<img className="galleryImageImg" src={"/uploads/" + image.filename + "." + image.extension} alt='Raw Honey Image' loading="lazy"></img>
				</picture>
			</a>
		);
	});
	return (imageElements);
}